import { Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { homeLocation } from "../..";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button onClick={() => logout({ returnTo: homeLocation })}>Log Out</Button>
  );
};

export default LogoutButton;
