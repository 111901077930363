import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  CardList,
  CheckLg,
  PencilFill,
  SkipStartFill,
} from "react-bootstrap-icons";

const ViewApiCredentials = ({ credentials, onEditSubmit }) => {
  const initialApiKey = credentials.apiKey;
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [apiKey, setApiKey] = useState(initialApiKey);
  const handleClose = () => {
    setIsEditing(false);
    setShow(false);
  };
  const handleShow = () => {
    setIsEditing(false);
    setShow(true);
  };
  const handleEdit = () => setIsEditing(true);
  const handleRevert = () => setApiKey(initialApiKey);
  const isApiKeyChanged = apiKey && apiKey !== initialApiKey;
  return (
    <>
      <Button variant="dark" onClick={handleShow}>
        <CardList /> Details
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "View"} Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isEditing ? (
            <>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="txtAppId">App ID</Form.Label>
                  <Form.Control
                    required
                    disabled
                    id="txtAppId"
                    type="text"
                    value={credentials.appId}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="txtApiKey">API Key</Form.Label>
                  <Form.Control
                    required
                    id="txtApiKey"
                    type="text"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    autoComplete="off"
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <ul>
              <li>
                <strong>App ID:</strong> {credentials.appId}
              </li>
              <li>
                <strong>API Key:</strong> {credentials.apiKey}
              </li>
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEditing ? (
            <>
              <Button
                variant="warning"
                disabled={!isApiKeyChanged}
                onClick={handleRevert}
              >
                <SkipStartFill /> Revert
              </Button>
              <Button
                variant="success"
                disabled={!isApiKeyChanged}
                onClick={() => onEditSubmit(credentials.appId, apiKey)}
              >
                <CheckLg /> Confirm
              </Button>
            </>
          ) : (
            <Button onClick={handleEdit}>
              <PencilFill /> Edit
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            <ArrowLeftCircleFill /> Return
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewApiCredentials;
