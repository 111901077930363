import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import ApiCredentialsListItem from "./ApiCredentialsListItem";
import AddApiCredentials from "./AddApiCredentials";
import {
  loadCredentials,
  deleteCredentialsRequest,
  editCredentialsRequest,
  addCredentialsRequest,
} from "../thunks";

const ApiCredentialsList = ({
  apiCredentials = [],
  isLoading,
  startLoadingList,
  onAddSubmit,
  onEditSubmit,
  onDeleteClicked,
}) => {
  useEffect(() => {
    startLoadingList();
  }, []);
  const loadingMessage = <div>Loading credentials...</div>;
  const content = (
    <div className="list-wrapper">
      <h1>List of API Credentials</h1>
      <Table striped bordered>
        <thead>
          <tr>
            <th scope="col">App ID</th>
            <th scope="col">API Key</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {apiCredentials.map((creds) => {
            return (
              <ApiCredentialsListItem
                key={creds.appId}
                credentials={creds}
                onEditSubmit={onEditSubmit}
                onDeleteClicked={onDeleteClicked}
              />
            );
          })}
        </tbody>
      </Table>
      <AddApiCredentials onAddSubmit={onAddSubmit} />
    </div>
  );
  return isLoading ? loadingMessage : content;
};

const mapStateToProps = (state) => ({
  apiCredentials: state.apiCredentials,
  isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  startLoadingList: () => dispatch(loadCredentials()),
  onAddSubmit: (id, apiKey) => dispatch(addCredentialsRequest(id, apiKey)),
  onDeleteClicked: (id) => dispatch(deleteCredentialsRequest(id)),
  onEditSubmit: (id, apiKey) => dispatch(editCredentialsRequest(id, apiKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiCredentialsList);
