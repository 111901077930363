import { Buffer } from "buffer";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ArrowLeftCircleFill, PlusSquareFill } from "react-bootstrap-icons";

const AddApiCredentials = ({ onAddSubmit }) => {
  const initialAppId = crypto.randomUUID();
  const initialApiKey = Buffer.from(
    crypto.getRandomValues(new Uint8Array(32))
  ).toString("base64");

  const [show, setShow] = useState(false);
  const [appId, setAppId] = useState(initialAppId);
  const [apiKey, setApiKey] = useState(initialApiKey);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const canSubmit = appId && apiKey;
  return (
    <>
      <Button onClick={handleShow}>
        <PlusSquareFill /> Add Account
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add an API Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="txtAppId">App ID</Form.Label>
              <Form.Control
                required
                id="txtAppId"
                type="text"
                value={appId}
                onChange={(e) => setAppId(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="txtApiKey">API Key</Form.Label>
              <Form.Control
                required
                id="txtApiKey"
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                autoComplete="off"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!canSubmit}
            onClick={() => onAddSubmit(appId, apiKey)}
          >
            <PlusSquareFill /> Add Account
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            <ArrowLeftCircleFill /> Return
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddApiCredentials;
