import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { HouseFill } from "react-bootstrap-icons";

const NotFound = () => (
  <div>
    <h2>Path not found...</h2>
    <Button variant="secondary" as={Link} to="/">
      <HouseFill /> Return Home
    </Button>
  </div>
);

export default NotFound;
