import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ArrowLeftCircleFill, XLg } from "react-bootstrap-icons";

const DeleteApiCredentials = ({ appId, onConfirm }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <XLg /> Delete
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm deletion of the API credentials for:{" "}
          <strong>{appId}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onConfirm}>
            <XLg /> Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            <ArrowLeftCircleFill /> Return
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteApiCredentials;
