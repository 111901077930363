import Home from "./nav/components/Home";
import NotFound from "./nav/components/NotFound";
import "./App.css";
import ApiCredentialsList from "./api-creds/components/ApiCredentialsList";
import AddApiCredentials from "./api-creds/components/AddApiCredentials";
import NavMenu from "./nav/components/NavMenu";
import { Router, Routes, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args}></Route>
);

const TestAuth = () => (
  <div>
    <h2>hello!</h2>
  </div>
);

const App = ({ history }) => {
  return (
    <div className="App">
      <Router history={history}>
        <NavMenu />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <ProtectedRoute path="/test-auth" component={TestAuth} />
          <ProtectedRoute
            exact
            path="/api-credentials"
            component={ApiCredentialsList}
          />
          <ProtectedRoute
            exact
            path="/api-credentials/add"
            component={AddApiCredentials}
          />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
