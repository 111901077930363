import {
  DELETE_CREDENTIALS,
  LOAD_CREDENTIALS_IN_PROGRESS,
  LOAD_CREDENTIALS_SUCCESS,
  VIEW_CREDENTIALS,
} from "./actions";

export const isLoading = (state = false, action) => {
  const { type } = action;

  switch (type) {
    case LOAD_CREDENTIALS_IN_PROGRESS:
      return true;
    case LOAD_CREDENTIALS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export const apiCredentials = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CREDENTIALS_SUCCESS: {
      const { credentials } = payload;
      return credentials;
    }
    case LOAD_CREDENTIALS_IN_PROGRESS:
    default:
      return state;
  }
};

export const details = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case VIEW_CREDENTIALS: {
      const { details } = payload;
      return details;
    }
    default:
      return state;
  }
};
