export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const createAccount = (appId, apiKey) => ({
  type: CREATE_ACCOUNT,
  payload: {
    appId,
    apiKey,
  },
});

export const DELETE_CREDENTIALS = "DELETE_CREDENTIALS";
export const deleteCredentials = (appId) => ({
  type: DELETE_CREDENTIALS,
  payload: {
    appId,
  },
});

export const LOAD_CREDENTIALS_IN_PROGRESS = "LOAD_CREDENTIALS_IN_PROGRESS";
export const loadCredentialsInProgress = () => ({
  type: LOAD_CREDENTIALS_IN_PROGRESS,
});

export const LOAD_CREDENTIALS_SUCCESS = "LOAD_CREDENTIALS_SUCCESS";
export const loadCredentialsSuccess = (credentials) => ({
  type: LOAD_CREDENTIALS_SUCCESS,
  payload: {
    credentials,
  },
});

export const VIEW_CREDENTIALS = "VIEW_CREDENTIALS";
export const viewCredentials = (details) => ({
  type: VIEW_CREDENTIALS,
  payload: {
    details,
  },
});
