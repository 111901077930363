import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { configureStore } from "./store";
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

export const history = createBrowserHistory({ basename: "/admin" });
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const homeUri = "https://cop.eazycollect.co.uk/admin";
// const homeUri = "http://localhost:3000/admin";
export const homeLocation = "";

const onRedirectCallback = (appState) => {
  history.replace(homeLocation);
}

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider domain={authDomain}
      clientId={authClientId}
      redirectUri={homeUri}
      onRedirectCallback={onRedirectCallback}>
      <Provider loading={<div>Loading...</div>} store={store}>
        <PersistGate persistor={persistor}>
          <App history={history} />
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
