import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import Profile from "./Profile";

const NavMenu = () => (
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand as={Link} to="/">
        Confirmation of Payee
      </Navbar.Brand>
      <Navbar.Collapse>
        <Nav>
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/api-credentials">
            API Credentials
          </Nav.Link>
          <Profile />
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default NavMenu;
