import { history } from "../index";
import {
  loadCredentialsInProgress,
  loadCredentialsSuccess,
  viewCredentials,
} from "./actions";

export const addCredentialsRequest =
  (appId, apiKey) => async (dispatch, getState) => {
    const body = JSON.stringify({ appId, apiKey });
    const response = await fetch("https://cop.eazycollect.co.uk/credentials", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body,
    });
    const creds = await response.json();
    history.push("/api-credentials");
  };

export const deleteCredentialsRequest = (id) => async (dispatch, getState) => {
  const response = await fetch(
    `https://cop.eazycollect.co.uk/credentials/${id}`,
    {
      method: "DELETE",
    }
  );
  dispatch(loadCredentials());
};

export const editCredentialsRequest =
  (appId, apiKey) => async (dispatch, getState) => {
    const body = JSON.stringify({ appId, apiKey });
    console.log(body);
    const response = await fetch(`https://cop.eazycollect.co.uk/credentials/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body,
    });
    const message = await response.json();
    history.push("/api-credentials");
  };

export const loadCredentials = () => async (dispatch, getState) => {
  dispatch(loadCredentialsInProgress());
  const response = await fetch("https://cop.eazycollect.co.uk/credentials");
  const credentials = await response.json();
  console.log(credentials);
  dispatch(loadCredentialsSuccess(credentials));
};

export const loadDetails = (id) => async (dispatch, getState) => {
  const response = await fetch(
    `https://cop.eazycollect.co.uk/credentials/${id}`
  );
  const creds = await response.json();
  console.log(creds);
  dispatch(viewCredentials(creds));
};
