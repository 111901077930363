import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isAuthenticated) {
    return (
      <div>
        <span className="text text-light">Hello {user.email}</span>
        <LogoutButton />
      </div>
    );
  } else {
    return (
      <div>
        <LoginButton />
      </div>
    );
  }
};

export default Profile;
