import { Stack } from "react-bootstrap";
import DeleteApiCredentials from "./DeleteApiCredentials";
import NewViewApiCredentials from "./ViewApiCredentials";

const ApiCredentialsListItem = ({
  credentials,
  onEditSubmit,
  onDeleteClicked,
}) => {
  const apiKeyMask = "*".repeat(Math.min(credentials.apiKey.length, 16));
  return (
    <tr className="list-item">
      <td>{credentials.appId}</td>
      <td>{apiKeyMask}</td>
      <td>
        <Stack direction="horizontal" gap={2}>
          <NewViewApiCredentials
            credentials={credentials}
            onEditSubmit={onEditSubmit}
          />
          <DeleteApiCredentials
            appId={credentials.appId}
            onConfirm={() => onDeleteClicked(credentials.appId)}
          />
        </Stack>
      </td>
    </tr>
  );
};

export default ApiCredentialsListItem;
